import React, { useState, useEffect, useRef } from "react";
import { FaMicrophone, FaStop, FaPaperPlane, FaHeadphones } from "react-icons/fa";
import '../../css/SpinningCircle.scss';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; 


const stopSoundEffect = new Audio('/sounds/button-20.mp3');
const startSoundEffect = new Audio('/sounds/click_sound.wav'); 


function MessageInputArea({
  onMessageSubmit,
  isAwaitingBotResponse,
  isDisabled,
  isListening,
  // toggleVoiceMode,
  isVoiceModeEnabled,
  startVoiceMode,
  stopVoiceMode,
  transcribedText,
  isListeningVoice,
  setTranscribedText,
  startVoiceOnlyMode,
  hasCredits,
  addMessage,
  // onVoiceModeToggle,



}) {
  const [message, setMessage] = useState("");
  const textAreaRef = useRef(); // Create a ref for the textarea
  const [isTranscribing, setIsTranscribing] = useState(false);
  const prevIsListeningRef = useRef(isListening);
  // const messagesEndRef = useRef(null);

  const countWords = (text) => {
    return text.trim().split(/\s+/).length;
  };

 //ding sound before user can speak
useEffect(() => {
  if (!prevIsListeningRef.current && isListening) {
    // isListening changed from false to true
    playStartSoundEffect();
  }
  prevIsListeningRef.current = isListening;
}, [isListening]);



  useEffect(() => {
    // Focus the textarea when it becomes enabled (not awaiting response and not disabled)
    if (!isAwaitingBotResponse && !isDisabled && textAreaRef.current) {
      textAreaRef.current.focus();
    }
  }, [isAwaitingBotResponse, isDisabled]);

    // Function to play the start sound effect
    const playStartSoundEffect = () => {
      startSoundEffect.play().catch((error) => {
        console.error("Error playing start sound effect", error);
      });
    };

    useEffect(() => {
      // Set the transcribed text in the input field
      if (!isVoiceModeEnabled && transcribedText) {
        setMessage(transcribedText);
        setIsTranscribing(false);
      }
    }, [transcribedText, isVoiceModeEnabled]);
  

     // Function to play the stop sound effect
  const playStopSoundEffect = () => {
    stopSoundEffect.play().catch((error) => {
      console.error("Error playing stop sound effect", error);
    });
  };
  

  const handleSubmit = (e) => {
    e.preventDefault();

    if (message.trim() && !isAwaitingBotResponse) {
      onMessageSubmit(message);
      setMessage(""); // Clear the input after sending
    }
  };


  const handleToggleVoiceMode = () => {
    if (!hasCredits) {
      // Display the no credits message in the chatbot interface
      addMessage("bot", "You have exceeded the usage limits of your current plan. To continue enjoying uninterrupted service, please upgrade your plan at https://psyfy.ai/credits");
      return;
    }
    console.log("toggle voice mode")
    if (isListening) {
      playStopSoundEffect(); 
      // playStartSoundEffect(); // Play the start sound when the spinning circle shows (recording starts)
      stopVoiceMode();
      
      

    } else {
      // playStopSoundEffect(); 
      playStartSoundEffect(); // Play the stop sound when the spinning circle hides (recording stops)
      startVoiceMode();
      

    }

    // toggleVoiceMode();
  };


  const handleToggleVoiceOnlyMode = () => {

    if (!hasCredits) {
      // Display the no credits message in the chatbot interface
      addMessage("bot", "You have exceeded the usage limits of your current plan. To continue enjoying uninterrupted service, please upgrade your plan at https://psyfy.ai/credits");
      return;
    }

    console.log("toggle voice only mode")
    if (isListening) {
      playStartSoundEffect(); // Play the start sound when the spinning circle shows (recording starts)
      stopVoiceMode();
      

    } else {
      playStopSoundEffect(); // Play the stop sound when the spinning circle hides (recording stops)
      startVoiceOnlyMode();
      setIsTranscribing(true);
      // startSilenceDetection();

    }

  };

  // Function to handle key presses in the textarea
  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      if (isAwaitingBotResponse) {
        alert("The bot is still responding. Please wait before submitting.");
      } else if (isVoiceModeEnabled) {
        alert("You can't submit the message when you are in voice mode.");
      } else {
        handleSubmit(e);
      }
    }
  };




  const storedLanguage = localStorage.getItem("language") || "english";
  const transcribingText = storedLanguage === "chinese" ? "正在语音识别...." : "is transcribing text...";
  // Set placeholder text based on language
  const placeholderText = storedLanguage === "chinese"
    ? "开始输入...(Shift+Enter换行，最多不超过500字)"
    : "Start typing... (Shift+Enter for a new line, max 500 words)";


  return (
    <form className="msger-inputarea" onSubmit={handleSubmit}>
         {!isListening && !isVoiceModeEnabled && (
         <Tippy
         content={
           storedLanguage === "chinese"
             ? "点击按钮进入语音模式。在语音模式中，您可以与Mia进行不间断的对话，无需按下任何按钮。听到提示音后即可开始说话。"
             : "Click the button to start voice mode. While in voice mode, you can have a back-and-forth conversation with Mia without pressing any buttons. Begin speaking whenever you hear the click sound."
         }
         delay={[100, 0]}
         arrow={false}
         className="custom-tooltip"
       >
          <button
            type="button"
            className="mic-button"
            onClick={handleToggleVoiceMode}
          >
            <FaHeadphones />
          </button>
        </Tippy>
      )}

      {/* When Voice Mode is enabled, show the stop button */}
      {isVoiceModeEnabled && (
        <Tippy
          content="Voice mode stopped. Click to start again."
          delay={[100, 0]}
          arrow={false}
          className="custom-tooltip"
        >
          <button
            type="button"
            className="mic-button"
            onClick={handleToggleVoiceMode}
          >
            <FaStop />
          </button>
        </Tippy>
      )}
 
      <textarea
        ref={textAreaRef}
        type="text"
        className="msger-input"
        id="user-message"
        placeholder={isTranscribing ? transcribingText : placeholderText}
        value={isTranscribing ? "" : message}
        onKeyDown={handleKeyPress}
        onChange={(e) => {
          const inputText = e.target.value;
          const wordCount = inputText.trim().split(/\s+/).length;

          // Cap the message at 250 words
          if (wordCount <= 500) {
            setMessage(inputText);
          } else {
            // Trim message to the first 250 words
            const limitedText = inputText.trim().split(/\s+/).slice(0, 500).join(' ');
            setMessage(limitedText);
          }

          // Dynamically adjust textarea height based on content
          textAreaRef.current.style.height = "auto"; // Reset height
          textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`; // Adjust height to match content
        }}
        name="user-message"
        disabled={ isTranscribing} // Disable input when awaiting bot response
      />

      {message.trim() && !isAwaitingBotResponse ? (
              <button type="submit" className="mic-button">
                <FaPaperPlane />
              </button>
            ) : (
              <Tippy
              content={
                storedLanguage === "chinese"
                  ? "点击开始语音转文字模式。您的语音将转换为文字。再次点击停止录音。"
                  : "Click to start speech-to-text mode. Your speech will be converted into text. Click again to stop recording."
              }
              delay={[100, 0]}
              arrow={false}
              className="custom-tooltip"
            >
              <button
                type="button"
                className="mic-button"
                onClick={handleToggleVoiceOnlyMode}
                disabled={isAwaitingBotResponse}
              >
                 {isListening ? <FaStop /> : <FaMicrophone />}
              </button>
              </Tippy>
            )}



    </form>
  );
}

export default MessageInputArea;
