import React, { useState, useEffect, useRef, useCallback, useLayoutEffect} from "react";
import { useNavigate} from "react-router-dom";
import { io } from "socket.io-client";
import { v4 as uuidv4 } from "uuid";
import CONFIG from "../../config";
import "../../css/style_chatbot.scss";
// import { FaVolumeMute, FaVolumeUp } from "react-icons/fa";
// Import custom hooks and utility components.
import { useChatbotMessages } from "../../hooks/useChatbotMessage";
import { useUserAgreement } from "../../hooks/useUserAgreement";
import {usePsyfyClient} from "../../hooks/psyfyClient";
import Logger from "../../utils/logger";

// Import UI components.
import MessageList from "./MessageList";
import MessageInputArea from "./MessageInputArea";
import AgreementComponent from "./AgreementComponent";

import {fetchUserAgreementStatus} from "../../hooks/chatbotService"
// import debounce from "lodash.debounce"; 
import { useVoiceMode } from "../../hooks/useVoiceMode";
import SpinningCircle from '../../hooks/ SpinningCircle'; 

function LoadingModal({ isOpen }) {
  if (!isOpen) return null;

  return (
    <div className="modal-backdrop">
      <div className="modal-content">
        <h2>Psyfy is preparing for the conversation...</h2>
      </div>
    </div>
  );
}


function Chatbot() {
  
  const socketRef = useRef(); // Reference to the WebSocket connection.
  const { checkAndSyncSession, fetchConversationHistory, prepareAndSendBotMessage, sendRating, requestSummarySurrogate, fetchUserData,  prepareAndSendBotMessageInitial, handleLanguageChange, sendLanguageToBackend, fetchUserLanguageFromBackend, fetchUserConversationTurns} = usePsyfyClient();
   
  // Custom hook to manage chat messages.
  const [messages, addMessage, updateMessages] = useChatbotMessages(
    JSON.parse(sessionStorage.getItem("chatMessages") || "[]")
  ); //author, message_id, text, timestamp, type, stateinfo, nodetype
  
    // State management for UI elements.
  const [sessionId, setSessionId] = useState(
    sessionStorage.getItem("uuid") || false
  );

  const [hasAgreed, setHasAgreed] = useUserAgreement(
    sessionStorage.getItem("hasAgreed") === "true"
  );

  const [showSessionCompleteButton, setShowSessionCompleteButton] =
    useState(false);

  const [showAgreement, setShowAgreement] = useState(false); 

  const [isAwaitingBotResponse, setIsAwaitingBotResponse] = useState(false);
  // const userEmail = localStorage.getItem("userEmail");

  const navigate = useNavigate();

  const [compactMode, setCompactMode] = useState(() => {
    // Correcting to check for compactMode instead of darkMode
    return localStorage.getItem("compactMode") === "true";
  });

  //check if user is on waitlist
  const [isOnWaitlist, setIsOnWaitlist] = useState(false); 
  //const { isOnWaitlist } = useWaitlistStatus(userEmail);
  const [isRepeatUser, setIsRepeatUser] = useState(false);
  const [hasCredits, setHasCredits] = useState(false);
  // const [initialMessageSent, setInitialMessageSent] = useState(false);
  const [initialMessageSent, setInitialMessageSent] = useState(() => {
    const chatMessagesJSON = sessionStorage.getItem("chatMessages");
    const chatMessages = chatMessagesJSON ? JSON.parse(chatMessagesJSON) : [];
    return chatMessages.length > 0;
  });
  const [isLoading, setIsLoading] = useState(false);
  const [promptOnUnload, setPromptOnUnload] = useState(false);


  const [language, setLanguage] = useState(null);
  const [isTyping, setIsTyping] = useState(false);


  const [isProcessing, setIsProcessing] = useState(false);
  const [conversationTurns, setConversationTurns] = useState(0);

  const [isMoodProfileUnlocked, setIsMoodProfileUnlocked] = useState(false);
const [isPersonalityProfileUnlocked, setIsPersonalityProfileUnlocked] = useState(false);
const [initialTurns, setInitialTurns] = useState(null);
const humanMessagesCount = messages.filter(msg => msg.type === 'user').length;

const MOOD_PROFILE_TURNS = 20;
const PERSONALITY_PROFILE_TURNS = 60;

const [isProgressBarVisible, setIsProgressBarVisible] = useState(() => {
  return localStorage.getItem("progressBarClosed") !== "true";
});

const handleCloseProgressBar = () => {
  if (isPersonalityProfileUnlocked) {
    setIsProgressBarVisible(false);
    localStorage.setItem("progressBarClosed", "true");
  } else {
    setIsProgressBarVisible(false);
    sessionStorage.setItem("progressBarClosed", "true");
  }
};


useEffect(() => {
  const fetchInitialTurns = async () => {
    try {
      const email = localStorage.getItem('userEmail');
      if (!email) {
        throw new Error('Email not found in local storage');
      }
      const response = await fetchUserConversationTurns(email);
      const initialTurnsValue = response.turns;
      setConversationTurns(initialTurnsValue);
      setInitialTurns(initialTurnsValue); // Store initialTurns in state
      console.log('initial T', initialTurnsValue);

      // Check if any profile is already unlocked
      setIsMoodProfileUnlocked(initialTurnsValue >= MOOD_PROFILE_TURNS);
      setIsPersonalityProfileUnlocked(initialTurnsValue >= PERSONALITY_PROFILE_TURNS);
    } catch (error) {
      console.error('Error fetching initial conversation turns:', error);
      // Do not reset initialTurns or conversationTurns
      // Optionally, set an error state or show an error message to the user
    }
  };

  fetchInitialTurns();
}, []);



useEffect(() => {
 // const humanMessagesCount = messages.filter(msg => msg.type === 'user').length;
//  console.log('initial + human', (initialTurns || 0) + humanMessagesCount);

//  const updatedTurns = (initialTurns || 0) + humanMessagesCount;
 // setConversationTurns(updatedTurns);

  // Check and set profile unlock states
  if (initialTurns >= MOOD_PROFILE_TURNS && !isMoodProfileUnlocked) {
    setIsMoodProfileUnlocked(true);
  }
  console.log('initial turns', initialTurns)
  if (initialTurns >= PERSONALITY_PROFILE_TURNS && !isPersonalityProfileUnlocked) {
    setIsPersonalityProfileUnlocked(true);
  }
}, [messages, initialTurns, isMoodProfileUnlocked, isPersonalityProfileUnlocked]);



  useLayoutEffect(() => {
    if (messages.length > 0) {
      const scrollToBottom = () => {
        endOfMessagesRef.current?.scrollIntoView({ behavior: "smooth" });
        endOfContentRef.current?.scrollIntoView({ behavior: "smooth" });
      };
  
      // Delay to ensure messages are rendered
      setTimeout(() => {
        if (showSessionCompleteButton) {
          setTimeout(scrollToBottom, 0);
        } else {
          scrollToBottom();
        }
      }, 0); // Adjust the delay if necessary
    }
  }, [messages, showSessionCompleteButton]);

  //ProgressBar component
  const showMoodProgress = !isMoodProfileUnlocked;
const progress = showMoodProgress
  ? Math.min((conversationTurns / MOOD_PROFILE_TURNS) * 100, 100)
  : Math.min((conversationTurns / PERSONALITY_PROFILE_TURNS) * 100, 100);

  console.log('this is the progress', progress)
//  const progress = Math.min((conversationTurns / REQUIRED_TURNS) * 100, 100);
//  console.log('this is progress', progress)



  function ProgressBar({ progress }) {
    return (
      <div className="progress-bar-container">
        <div className="progress-bar" style={{ width: `${progress}%` }}>
          {progress >= 100 ? "Profile Unlocked!" : `${Math.round(progress)}%`}
        </div>
      </div>
    );
  }


    // Fetch user language on load
    useEffect(() => {
      const fetchLanguage = async () => {
        const storedLanguage = localStorage.getItem("language");
        console.log("storedLanguage", storedLanguage)
        if (!storedLanguage) {
          try {
            // Fetch user language from the backend
            const userLanguage = await fetchUserLanguageFromBackend();
    
            if (userLanguage) {
              setLanguage(userLanguage); // Set language from backend
              localStorage.setItem("language", userLanguage); // Save to localStorage
            }
          } catch (error) {
            console.error("Error fetching language from backend:", error);
          }
        } else {
          // If language is already stored, use the stored value
          setLanguage(storedLanguage);
        }
      };

    
      fetchLanguage();
    }, []);
  
      // Send language to backend whenever it changes
  useEffect(() => {
    if (language) {
      sendLanguageToBackend(language);
    }
  }, [language]);

  
   // State to track if conversation is synced.
  const [isConversationSynced, setIsConversationSynced] = useState(
    sessionStorage.getItem("isConversationSynced") === "true"
  );
  
  // References to track certain elements in the DOM.
  const endOfMessagesRef = useRef(null);
  const endOfContentRef = useRef(null); // New ref for the session complete buttons.
  const userStatusRef = useRef({ hasCredits, isRepeatUser, isOnWaitlist });
  const [isSessionChecked, setIsSessionChecked] = useState(false);



  const handleVoiceMessage = (message) => {
    if (!isAwaitingBotResponse) {
      if (message === "We didn't get your speech. Please say it again.") {
        // Add bot's response for the specific case
        addMessage('bot', message);
      } else {
        // Normal processing of valid voice input
        handleMessageSubmit(message);
      }
    }
  };

  // Callback function when the user stops speaking
  const handleVoiceStopListening = () => {
    setIsAwaitingBotResponse(true);
  };



  const { isListening, startListening, startListeningVoiceOnly, startVoiceOnlyMode, stopListening, speak, isSpeaking, volume, startVoiceMode, stopVoiceMode, transcribedText, setTranscribedText,  isVoiceModeEnabled, isVoiceModeEnabledRef, currentModeRef} = useVoiceMode(handleVoiceMessage, handleVoiceStopListening);




// Control the prompt based on loading state, user cannot refresh the page when memory is loading
useEffect(() => {
  if (isLoading) {
    setPromptOnUnload(true);
  } else {
    setPromptOnUnload(false);
  }

}, [isLoading]);


  // Fetch user data on load using userEmail.
  useEffect(() => {
    const userEmail = localStorage.getItem("userEmail");
    if (userEmail) {
      fetchUserData(userEmail)
        .then(data => {
         
          setIsOnWaitlist(data.isOnWaitlist);
          setIsRepeatUser(data.isRepeatUser);
          setHasCredits(data.hasCredits);
          // Logger.debug("user info", data)
       
        })
        .catch(error => {
          Logger.debug("Failed to fetch user data:", error);
        });
    }
  }, []);

   // Update user status ref whenever related states change. Check whether user has credits, is a repeat user or on a waitlist
  useEffect(() => {
    userStatusRef.current = {
        hasCredits: hasCredits,
        isRepeatUser: isRepeatUser,
        isOnWaitlist: isOnWaitlist
    };
  

}, [hasCredits, isRepeatUser, isOnWaitlist]);




  useEffect(() => {
    // Initialize the WebSocket connection
    socketRef.current = io(`${CONFIG.BASE_URL}/chat`, {
        transports: ["websocket"],
        withCredentials: true,
        reconnection: true,
        reconnectionAttempts: Infinity,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
        randomizationFactor: 0.5
    });
  
    // Handle connection
    socketRef.current.on('connect', () => {
        Logger.debug("WebSocket connected", socketRef.current.id);
    });
  
      // Handle disconnections
      socketRef.current.on('disconnect', (reason) => {
        Logger.debug("WebSocket disconnected", reason);
        if (reason !== 'io client disconnect') {  // Avoid reconnection on client-initiated disconnects
            socketRef.current.connect();  // Attempt to reconnect manually
        }
    });
  
    // Handle connection errors
    socketRef.current.on('connect_error', (error) => {
        Logger.debug("Connection Error", error);
    });
  
    // Cleanup function to clear interval and disconnect socket when component unmounts
    return () => {

        if (socketRef.current) {
            socketRef.current.disconnect();
            Logger.debug("WebSocket disconnected on component unmount");
        }
    };
  }, []);
    

 //handle user agreement
 //first time user will see this agreement tab
 const handleAgree = () => {
  sessionStorage.setItem("hasAgreed", "true");

  setHasAgreed(true);
  setShowAgreement(false);
};



  //create a session id when the window is open
  useEffect(() => {
    if (!sessionStorage.getItem("uuid")) {
      const newSessionId = uuidv4();
      sessionStorage.setItem("uuid", newSessionId);
      setSessionId(newSessionId);
    
    }
  }, []);

    //this function syncs the conversation when the chatbot is opened
    //it fetches the conversation history from the database and adds the messages to the chatbot
    //it also sets the isConversationSynced state to true if there are actual text messages
    //it also adds a message to the chatbot if the user is out of credits 
    const syncConversation = useCallback(async (sessionId) => {
      if (!sessionId) {
        Logger.debug("No session ID provided for syncing conversation.");
        return;
      }
  
      try {
        const data = await fetchConversationHistory(sessionId);
        if (data && data.messages) {
          const limitedMessages = data.messages.slice(-20);
          limitedMessages.forEach((savedMessage) => {
            addMessage(
              savedMessage.type === "bot" ? "bot" : "user",
              savedMessage.text,
              null,
              savedMessage.timestamp,
              savedMessage.author
            );
          });
  
          const hasTextualContent = data.messages.some(message => message.text && message.text.trim().length > 0);
          if (hasTextualContent) {
            sessionStorage.setItem("isConversationSynced", "true");
            setIsConversationSynced(true);
          } else {
            sessionStorage.setItem("isConversationSynced", "false");
            setIsConversationSynced(false);
          }
  
          sessionStorage.setItem("chatMessages", JSON.stringify(data.messages));
  
          if (!userStatusRef.current.hasCredits) {
            addMessage(
              "bot",
              "You have exceeded the usage limits of your current plan. To continue enjoying uninterrupted service, please upgrade your plan at https://psyfy.ai/credits"
            );
          }
        } else {
          Logger.debug("No messages found for the given session ID.");
        }
      } catch (error) {
        Logger.debug(error.message);
      }
    }, [addMessage, fetchConversationHistory, setIsConversationSynced]);

//this is the first function that runs when the chatbot is opened
//it checks if the user is logged in, if the user has agreed to the terms, if the conversation has synced, and if the chat messages exist
//if the chat messages exist, it will load the conversation
//if the chat messages do not exist, it will send the initial message and load the conversation   
useEffect(() => {
  const handleSessionCheck = async () => {
    setIsLoading(true); // Start loading

    const userEmail = localStorage.getItem("userEmail");
    if (!userEmail) {
      navigate("/login");
      return;
    }

    try {
      const data = await fetchUserAgreementStatus(userEmail);
      setHasAgreed(data.hasAgreed);
      setShowAgreement(false);
      setIsRepeatUser(true);
      sessionStorage.setItem("hasAgreed", data.hasAgreed.toString());

      if (data.hasAgreed === false) {
        setShowAgreement(true);
        setIsRepeatUser(false);
      }

      const hasSynced = sessionStorage.getItem("hasSyncedConversation") === "true";
      const chatMessagesJSON = sessionStorage.getItem("chatMessages");
      const chatMessages = chatMessagesJSON ? JSON.parse(chatMessagesJSON) : [];
      const messagesExist = chatMessages.length > 0;
      const storedHasAgreed = sessionStorage.getItem("hasAgreed");

      if (storedHasAgreed && !messagesExist && !hasSynced) {
        const initialSessionId = sessionStorage.getItem("uuid");
        const sessionData = await checkAndSyncSession(userEmail, initialSessionId);

        if (sessionData.session_id) {
          setSessionId(sessionData.session_id);
          setHasAgreed(true);
          socketRef.current.emit("join_session", { sessionId: sessionData.session_id });
          await syncConversation(sessionData.session_id);
          sessionStorage.setItem("hasSyncedConversation", "true");
          sessionStorage.setItem("uuid", sessionData.session_id);
          setInitialMessageSent(true);
        } else {
          const messageIndex = uuidv4();
          const botResponseData = await prepareAndSendBotMessageInitial(messageIndex);
          const botResponse = botResponseData.reply;

          if (botResponse && !initialMessageSent) {
            addMessage("bot", botResponse);
            sessionStorage.setItem("chatMessages", JSON.stringify([botResponse]));
            setInitialMessageSent(true);
          } else {
            Logger.error("Failed to provide initial message.");
          }
        }
      }
    } catch (error) {
      Logger.error("Error while fetching user status or syncing session:", error);
      setShowAgreement(true);
    } finally {
      setIsLoading(false); // End loading
    }
  };

  if (!isSessionChecked) {
    handleSessionCheck();
    setIsSessionChecked(true);
  }

  return () => {
    setIsLoading(false); // Cleanup loading state on unmount
  };
}, [
  hasAgreed, 
  isSessionChecked, 
  addMessage, 
  checkAndSyncSession, 
  initialMessageSent, 
  navigate, 
  prepareAndSendBotMessageInitial, 
  setHasAgreed, 
  syncConversation
]);  

  // Handle receiving messages from the socket
  useEffect(() => {
    if (!socketRef.current) return;

    socketRef.current.on("receive_message", (messageInfo) => {
      Logger.debug("using websocket..........", messageInfo);




      //these are the displayed messages
      const messageAlreadyDisplayed = messages.some((msg) => {
        return msg.id === messageInfo.messageId; // Return the comparison result
      });

      //check if messages are already displayed. only show the messages that are not displayed
      if (!messageAlreadyDisplayed && messageInfo.sessionId === sessionId) {
        addMessage(
          messageInfo.type,
          messageInfo.text,
          null,
          messageInfo.messageId
        );
      }
    });

    return () => {
      socketRef.current.off("receive_message");
    };
  }, [sessionId, messages, addMessage]); 



  //helper function: toggle display mode
  const toggleCompactMode = () => {
    setCompactMode(!compactMode);
    localStorage.setItem("compactMode", !compactMode);
  };


  //generate a summary when user leave chat
  useEffect(() => {
  

    const handleBeforeUnload = (event) => {
      // Set a flag or timestamp to indicate the page is undergoing unload events
      // Example with timestamp

      if (promptOnUnload) {
        // Prevent the page from being closed
        event.preventDefault();
        event.returnValue = 'Changes you made may not be saved.';
      }
  

      sessionStorage.setItem("unloadTimestamp", Date.now().toString());

      // Preventing default and setting returnValue to trigger the dialog
      // Note: Custom messages are not supported in modern browsers
      event.preventDefault();
      event.returnValue =
        "You are about to exit the chat, are you sure you want to continue?";
    };

    const handleUnload = async (event) => {
      // Check if a short duration has passed since `beforeunload`,
      // which might indicate it's more likely a tab close than a refresh
      const unloadTimestamp = Number(sessionStorage.getItem("unloadTimestamp"));
      if (Date.now() - unloadTimestamp < 1000) {
        // 1 second threshold
        await callSummaryBackend();

      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("unload", handleUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("unload", handleUnload);
      sessionStorage.removeItem("unloadTimestamp"); // Cleanup
    };
  }, [requestSummarySurrogate, promptOnUnload]); // Empty array ensures this effect runs once on mount





//if user idle 30 mins, show reset conversation button 
//if user idle 12 hrs, call summary, then start the conversation
  useEffect(() => {
    let thirtyMinuteTimeoutId;
    let twelveHourTimeoutId;
  
    const handleUserAction = () => {
      // Clear any existing timeouts to reset the timers
      clearTimeout(thirtyMinuteTimeoutId);
      clearTimeout(twelveHourTimeoutId);
  
      // Set up a new timeout that triggers after 30 minutes of inactivity
      thirtyMinuteTimeoutId = setTimeout(() => {
        // Here you can set a state indicating the session is complete
        setShowSessionCompleteButton(true);
        Logger.info(
          "User has been inactive for 30 minutes. Session will be completed soon."
        );
      }, 28800000); // 1,800,000 milliseconds = 30 minutes  28800000
  
      // Set up another timeout for 12-hour inactivity actions
      twelveHourTimeoutId = setTimeout(async () => {
        // Clear chat messages from session storage
        sessionStorage.removeItem("chatMessages");
  
        // Reset the chat messages in the state
        updateMessages([]);
  
        try {

          await callSummaryBackend();

          const messageIndex = uuidv4();
          setIsAwaitingBotResponse(true);
          const botResponseData = await prepareAndSendBotMessageInitial(messageIndex);
          if (botResponseData && botResponseData.reply) {
  
            const decryptedData = botResponseData.reply;
            addMessage("bot", decryptedData); // Assuming decryptedData is the actual message text
            sessionStorage.setItem("chatMessages", JSON.stringify([{ type: "bot", text: decryptedData }]));
            setIsAwaitingBotResponse(false);

      
          } else {
            Logger.error("Failed to fetch initial message after inactivity.");
            // Fallback message if no data is returned or in case of an error
            addMessage("bot", "Welcome back! How have you been since our last session? 😊");
          }
        } catch (error) {
          Logger.error("Error in fetching initial message:", error);
          // Fallback message in case of exception
          addMessage("bot", "Welcome back! How have you been since our last session? 😊");
        }

      }, 43200000); // 43,200,000 milliseconds = 12 hours
    };
  
    // Add event listeners for user actions to reset the timer
    window.addEventListener("mousemove", handleUserAction);
    window.addEventListener("keydown", handleUserAction);
  
    // Clean up function in the return statement
    return () => {
      // Remove the event listeners and clear the timeouts
      window.removeEventListener("mousemove", handleUserAction);
      window.removeEventListener("keydown", handleUserAction);
      clearTimeout(thirtyMinuteTimeoutId);
      clearTimeout(twelveHourTimeoutId);
    };
  }, [
    setShowSessionCompleteButton,
    updateMessages,
    addMessage,
    requestSummarySurrogate,
    prepareAndSendBotMessageInitial,
  ]);

  //user click clear session bustton
  const callSummaryBackend = async () => {
    // Log the action and call the backend for a summary
    Logger.debug("Calling Summary Backend...");
    try {
      await requestSummarySurrogate();
      Logger.debug("Summary requested successfully.");
    } catch (error) {
      Logger.debug("Error requesting summary", error);
    }
  };
  
  const clearSessionMessages = async () => {
    // Confirm with the user before clearing the session
    if (window.confirm("Are you sure you want to clear this session's messages?")) {
      // Call the summary backend function right after confirmation
      setIsLoading(true); 

      await callSummaryBackend();

      // Retrieve current messages from sessionStorage
      const chatMessagesJSON = sessionStorage.getItem("chatMessages");
      const chatMessages = chatMessagesJSON ? JSON.parse(chatMessagesJSON) : [];

      // Retain the last 100 messages
      const retainedMessages = chatMessages.slice(-100);

      // Update sessionStorage with retained messages
      sessionStorage.setItem("chatMessages", JSON.stringify(retainedMessages));

      // Update state with retained messages
      updateMessages(retainedMessages);
      
  
      // Clear the session messages
      // updateMessages([]);
      // sessionStorage.removeItem("chatMessages");
  
      try {
        const messageIndex = uuidv4();
        const botResponseData = await prepareAndSendBotMessageInitial(messageIndex);
        if (botResponseData && botResponseData.reply) {
          const decryptedData = botResponseData.reply
          

          const newMessages = [...retainedMessages, { type: "bot", text: decryptedData }];
          addMessage("bot", decryptedData);
          //sessionStorage.setItem("chatMessages", JSON.stringify([{ type: "bot", text: decryptedData }]));
          sessionStorage.setItem("chatMessages", JSON.stringify(newMessages));
        } else {
          Logger.error("Failed to fetch initial message after session clear.");
          addMessage("bot", "Welcome back! How have you been since our last session? 😊");
        }
      } catch (error) {
        Logger.error("Error in fetching initial message after session clear:", error);
        addMessage("bot", "Welcome back! How have you been since our last session? 😊");
      }
  
      // Hide the session complete button
      setIsLoading(false);
      setShowSessionCompleteButton(false);
    }
  };

 
  //handle showing the reset conversation button
  const handleContinueConversation = () => {
    // Logic to continue the conversation
    // Assuming you have some functionality here
    setShowSessionCompleteButton(false); // Hide both buttons
  };

  //logic for scrolling down
  useEffect(() => {
    const scrollToBottom = () => {
      endOfMessagesRef.current?.scrollIntoView({ behavior: "smooth" });
      endOfContentRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    // Execute the scroll only after the DOM has been updated
    if (showSessionCompleteButton) {
      setTimeout(scrollToBottom, 0);
    } else {
      scrollToBottom();
    }
  }, [messages, showSessionCompleteButton]);

  //reference the last message or a dummy `div` at the end of a list of messages. The purpose of this could be to manage scrolling behavior, particularly to automatically scroll to the bottom of the chat when a new message is added.
  const handleMessageSubmit = useCallback(
    async (messageText) => {
      const trimmedMessageText = messageText.trim();
      if (!trimmedMessageText) {
        Logger.debug("Empty message, not adding to chat.");
        return;
      }
  
      if (isAwaitingBotResponse) {
        Logger.debug("Awaiting bot response, cannot send new message.");
        return;
      }
  
      setIsAwaitingBotResponse(true);
      setIsTyping(true);
  
      stopListening();
  
      // Add user's message to chat and session storage
      const updatedMessages = [
        ...messages,
        { type: "user", text: trimmedMessageText },
      ];
      sessionStorage.setItem("chatMessages", JSON.stringify(updatedMessages));
  
      // Generate timestamp and message ID
      const humanTimestamp = new Date().toISOString();
      const messageId = uuidv4();
  
      // Emit user's message to the server
      socketRef.current.emit("send_message", {
        type: "user",
        text: trimmedMessageText,
        messageId,
        sessionId,
        humanTimestamp,
      });
  
      try {
        // Send message to bot and get response
        const botResponseData = await prepareAndSendBotMessage(
          messages,
          trimmedMessageText,
          messageId,
          humanTimestamp
        );

         // Handle case where the user is out of credits
        if (botResponseData && botResponseData.error === 'You have exceeded the usage limits of your current plan. To continue enjoying uninterrupted service, please upgrade your plan at https://psyfy.ai/credits') {
          addMessage("bot", botResponseData.error);  // Display the message in the chat bubble
          setIsAwaitingBotResponse(false);           // Stop awaiting response
          setIsTyping(false);
          return;
        }

  
        if (botResponseData && botResponseData.reply) {
          let botResponse = botResponseData.reply;
  
          // Split bot response into multiple messages if needed
          let botMessages = botResponse.split(/\n\s*\n/).map((msg) => msg.trim());
  
          setTimeout(async () => {
          // Iterate over each bot message
          for (let i = 0; i < botMessages.length; i++) {
            const part = botMessages[i];
            const partMessageId = uuidv4();
  
            // Emit bot's message to the server
            socketRef.current.emit("send_message", {
              type: "bot",
              text: part,
              messageId: partMessageId,
              sessionId,
            });
  
            // Optionally, add the bot's message to your local state here
          }
  
          // Speak the bot's message and wait until it's done
          // if (isVoiceModeEnabled) {
          // if(isVoiceModeEnabledRef){
          //   console.log('speak message')
          // await speak(botMessages);
          // }

          if (currentModeRef.current === 'voice') {
            console.log('speak message');
            await speak(botMessages);
          }
  
          // After bot finishes speaking, reset flags and start listening
          setIsAwaitingBotResponse(false);
          setIsTyping(false);
          setIsProcessing(false); 


        }, 2000); // 2-second delay
        setIsTyping(false);
        } else {
          // Handle case where bot response is invalid
          throw new Error("Invalid bot response");
        }
      } catch (error) {
        Logger.error("Error sending message to bot:", error);
        const errorMessage =
          "An error occurred while processing your message. Please try again.";
        addMessage("bot", errorMessage);
        setIsAwaitingBotResponse(false);
        setIsTyping(false);
        setIsProcessing(false); 

      }
    },
    [
      addMessage,
      messages,
      isAwaitingBotResponse,
      prepareAndSendBotMessage,
      setIsAwaitingBotResponse,
      setIsTyping,
      socketRef.current,
      speak,
      startListening,
      stopListening,
      sessionId,
      startListeningVoiceOnly,
      isVoiceModeEnabled,
    ]
  );
  
  //retrieve rating from form and send it back to the server

  const handleRating = async (ratingType, messageId) => {
    let localUpdatedMessages = [...messages];
    localUpdatedMessages = localUpdatedMessages.map((message) =>
      message.id === messageId
        ? {
            ...message,
            ratingUp:
              ratingType === "up" ? !message.ratingUp : message.ratingUp,
            ratingDown:
              ratingType === "down" ? !message.ratingDown : message.ratingDown,
          }
        : message
    );

    sessionStorage.setItem(
      "chatMessages",
      JSON.stringify(localUpdatedMessages)
    );
    updateMessages(localUpdatedMessages); //updates the state

    //  handling the API call with updated local variable
    const ratedMessage = localUpdatedMessages.find(
      (message) => message.id === messageId
    );
    if (ratedMessage) {
      Logger.debug(ratedMessage); // Make sure you're logging the right variable
      const sessionId = sessionStorage.getItem("uuid") || ""; // Ensure you get the session ID as needed
      await sendRating(ratedMessage, ratingType, messageId, sessionId);
    }
  };

  //user can scroll down the messages
  useEffect(() => {
    if (isScrolledToBottomBeforeUpdate) {
      endOfMessagesRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  const isScrolledToBottomBeforeUpdate = () => {
    const { scrollTop, scrollHeight, clientHeight } =
      document.querySelector(".msger-chat");
    return scrollHeight - scrollTop === clientHeight;
  };



  const storedLanguage = localStorage.getItem("language");
  return (
    <>
    
  
      <div className="chatbot-page">
      {/* {isProgressBarVisible && (
        <div className="progress-bar-text-wrapper">
          <button className="close-button" onClick={handleCloseProgressBar}>
            x
          </button>

          <div className="progress-bar-wrapper">
            <ProgressBar progress={progress} />
          </div> */}
{/* 
          {progress < 100 ? (
            showMoodProgress ? (
              <p className="progress-description">
                {storedLanguage === "chinese"
                  ? `在 ${MOOD_PROFILE_TURNS} 句对话后查看您的情绪分析。聊天越多，分析越准确！`
                  : `View your mood profile after ${MOOD_PROFILE_TURNS} chat turns. The more you chat, the more accurate your profile will be!`}
              </p>
            ) : (
              <p className="progress-description">
                {storedLanguage === "chinese"
                  ? `在 ${PERSONALITY_PROFILE_TURNS} 句对哈后查看您的性格分析。聊天越多，分析越准确！`
                  : `View your personality profile after ${PERSONALITY_PROFILE_TURNS} chat turns. The more you chat, the more accurate your profile will be!`}
              </p>
            )
          ) : (
            <p className="progress-message">
              {isPersonalityProfileUnlocked ? (
                <>
                  {storedLanguage === "chinese"
                    ? `恭喜！您的个性档案已解锁。`
                    : `Congratulations! Your personality profile is now unlocked.`}
                  <span
                    className="view-profile-link"
                    onClick={() => navigate('/personprofile')}
                    style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
                  >
                    {storedLanguage === "chinese" ? '查看档案！' : 'View your Profile!'}
                  </span>
                </>
              ) : (
                <>
                  {storedLanguage === "chinese"
                    ? `恭喜！您的情绪档案已解锁。`
                    : `Congratulations! Your mood profile is now unlocked.`}
                  <span
                    className="view-profile-link"
                    onClick={() => navigate('/moodprofile')}
                    style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
                  >
                    {storedLanguage === "chinese" ? '查看档案！' : 'View your Profile!'}
                  </span>
                </>
              )}
            </p>
          )}

 
    </div>

)} */}
   
    

        <div className="body_chat">

          <section className={`msger ${compactMode ? "compact" : ""}`}>
            {/* <header className="msger-header"></header> */}

            <main className="msger-chat">
              {!hasAgreed && showAgreement && (
                <AgreementComponent onAgree={handleAgree} />
              )}

              {hasAgreed && sessionId && (
                <>
                  {/* submit rating */}
                  <MessageList
                    messages={messages}
                    onRating={handleRating}
                    endOfMessagesRef={endOfMessagesRef}
                    isExperiment={false}
                    isTyping={isTyping} 
                  />
                  {showSessionCompleteButton && (
                    <div
                      ref={endOfContentRef}
                      className="session-complete-container"
                    >
                      <button
                        className="session-complete-btn"
                        onClick={clearSessionMessages}
                      >
                        Start a new session
                      </button>

                      <button
                        className="session-action-btn"
                        onClick={handleContinueConversation}
                      >
                        Continue Conversation
                      </button>
                    </div>
                  )}
                </>
              )}
            </main>

            {isListening && (
              <SpinningCircle isSpeaking={isSpeaking} volume={volume} />
            )}
             {isProcessing && !isListening && (
              <div className="processing-text">Processing...</div>
            )}

            <MessageInputArea
              onMessageSubmit={handleMessageSubmit}
              onConversationEnd={requestSummarySurrogate}
              isAwaitingBotResponse={isAwaitingBotResponse || isLoading || isTyping }
              isDisabled={!hasAgreed || isLoading || isTyping || !initialMessageSent}
              isListening={isListening}
              // toggleVoiceMode={toggleVoiceMode}
              isProcessing={isProcessing}
              startVoiceMode={startVoiceMode}
              startVoiceOnlyMode={startVoiceOnlyMode}
              stopVoiceMode={stopVoiceMode}
              transcribedText={transcribedText}
              setTranscribedText={setTranscribedText}
              isVoiceModeEnabled={isVoiceModeEnabled} 
              hasCredits={hasCredits}
              addMessage={addMessage} 
              // onVoiceModeToggle={handleVoiceModeToggle}
           
            
            />
        
          </section>
        </div>
      </div>
      <LoadingModal isOpen={isLoading || !initialMessageSent} />
    </>
  );
}

export default Chatbot;
