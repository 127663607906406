import React from 'react';
import { Radar } from 'react-chartjs-2';
import { Chart as ChartJS, RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend } from 'chart.js';
import './mood_profile.scss';

ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend);

const PersonalityProfilePage: React.FC = () => {
  // Sample scores for the Big Five personality traits
  const bigFiveScores = {
    openness: 70,
    conscientiousness: 80,
    extraversion: 65,
    agreeableness: 75,
    neuroticism: 50,
  };
  
  // Sample confidence score
  const confidenceScore = 85; 

  const data = {
    labels: [
      'Openness',
      'Conscientiousness',
      'Extraversion',
      'Agreeableness',
      'Neuroticism',
    ],
    datasets: [
      {
        label: 'Your Personality Profile',
        data: Object.values(bigFiveScores),
        backgroundColor: 'rgba(34, 202, 236, 0.2)',
        borderColor: 'rgba(34, 202, 236, 1)',
        borderWidth: 2,
        pointBackgroundColor: 'rgba(34, 202, 236, 1)',
      },
    ],
  };

  const options = {
    scales: {
      r: {
        angleLines: {
          display: true,
        },
        suggestedMin: 0,
        suggestedMax: 100,
        ticks: {
          stepSize: 20,
        },
      },
    },
    plugins: {
      legend: {
        position: 'top' as const,
      },
    },
  };

  // Explanatory text for each trait
  const traitDescriptions = {
    openness:
      'Openness reflects the degree of intellectual curiosity, creativity, and a preference for novelty and variety.',
    conscientiousness:
      'Conscientiousness indicates a tendency to be organized and dependable, show self-discipline, and aim for achievement.',
    extraversion:
      'Extraversion involves energy, positive emotions, and the tendency to seek stimulation and the company of others.',
    agreeableness:
      'Agreeableness reflects a tendency to be compassionate and cooperative rather than suspicious and antagonistic towards others.',
    neuroticism:
      'Neuroticism denotes the tendency to experience unpleasant emotions easily, such as anger, anxiety, or depression.',
  };

    // Explanatory text for each trait
    const visionaryDescription = `
    As a Visionary, You have a relentless drive for innovation and a natural curiosity 
    that propels yourself to explore uncharted territories. You constantly weave creative 
    solutions, blending logic with imagination, and foreseeing future trends that others 
    might overlook. The decision to launch your own company stemmed from a deep-seated 
    desire to solve problems that others had overlooked, driven by a unique blend of 
    intellectual curiosity and an acute awareness of emerging trends.
  `;

  return (
    <div className="personality-profile-page">
      <h2>Personality Profile</h2>
      <div className="profile-layout">
        <div className="left-column">
          <div className="confidence-score">
            <h3>Confidence Score</h3>
            <div className="score-circle">
              <span>{confidenceScore}%</span>
            </div>
            <p>
              This score reflects the confidence level of your personality assessment based on the data provided.
            </p>
          </div>
        </div>
        <div className="middle-column">
          <div className="chart-container">
            <Radar data={data} options={options} />
          </div>
          <div className="visionary-text">
            <p>{visionaryDescription}</p>
          </div>
        </div>
      
        <div className="right-column">
        <div className="trait-descriptions">
            {Object.entries(traitDescriptions).map(([trait, description]) => (
              <div key={trait} className="trait">
                <h3>{trait.charAt(0).toUpperCase() + trait.slice(1)}</h3>
                <p>{description}</p>
              </div>
            ))}
          </div>
          </div>
      </div>
      
    </div>
  );
};


//   return (
//         <div className="personality-profile-page">
//           <h2>Personality Profile</h2>
//           <div className="chart-and-confidence">
//             <div className="chart-container-personality">
//               <Radar data={data} options={options} />
//             </div>
//             <div className="confidence-score">
//               <h3>Confidence Score</h3>
//               <div className="score-circle">
//                 <span>{confidenceScore}%</span>
//               </div>
//               <p>
//                 This score reflects the confidence level of your personality
//                 assessment based on the data provided.
//               </p>
//             </div>
//           </div>
//           <div className="trait-descriptions">
//             {Object.entries(traitDescriptions).map(([trait, description]) => (
//               <div key={trait} className="trait">
//                 <h3>{trait.charAt(0).toUpperCase() + trait.slice(1)}</h3>
//                 <p>{description}</p>
//               </div>
//             ))}
//           </div>
//         </div>
//       );
//     };
    

export default PersonalityProfilePage;
