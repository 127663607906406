import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import './mood_profile.scss';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const MoodProfilePage: React.FC = () => {
  // Sample data for positive and negative affect scores over days
  const days = ['Day 1', 'Day 2', 'Day 3', 'Day 4', 'Day 5'];

  const negativeAffectScores = [70, 75, 80, 78, 85]; // Single set of scores for positive affect
  const positiveAffectScores = {
    set1: [40, 45, 50, 48, 52], // Example set 1 for negative affect
    set2: [35, 38, 42, 40, 45], // Example set 2 for negative affect
    set3: [50, 55, 58, 57, 60]  // Example set 3 for negative affect
  };

  const negativeAffectData = {
    labels: days,
    datasets: [
      {
        label: 'Tension Meter',
        data: negativeAffectScores,
        borderColor: 'rgba(75, 192, 192, 1)',
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderWidth: 2,
        tension: 0.4, // For a smoother curve
      }
    ],
  };

  const positiveAffectData = {
    labels: days,
    datasets: [
      {
        label: 'Mood Resilience Score',
        data: positiveAffectScores.set1,
        borderColor: 'rgba(255, 99, 132, 1)',
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderWidth: 2,
        tension: 0.4,
      },
      {
        label: 'Calmness Level',
        data: positiveAffectScores.set2,
        borderColor: 'rgba(54, 162, 235, 1)',
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
        borderWidth: 2,
        tension: 0.4,
      },
    //   {
    //     label: 'Negative Affect Set 3',
    //     data: positiveAffectScores.set3,
    //     borderColor: 'rgba(255, 206, 86, 1)',
    //     backgroundColor: 'rgba(255, 206, 86, 0.2)',
    //     borderWidth: 2,
    //     tension: 0.4,
    //   }
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: 'Mood Profile',
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Days',
        },
      },
      y: {
        title: {
          display: true,
          text: 'Scores',
        },
        min: 0,
        max: 100, // Adjust as needed based on your data
      },
    },
  };

  return (
    <div className="mood-profile-page">
      <h2>Mood Profile</h2>
      <div className="chart-container">
        <div className="positive-chart">
          <h3>Positive Affect</h3>
          <Line data={positiveAffectData} options={options} />
        </div>
        <div className="negative-chart">
          <h3>Negative Affect</h3>
          <Line data={negativeAffectData} options={options} />
        </div>
      </div>
    </div>
  );
};

export default MoodProfilePage;
