// useVoiceMode.tsx
import { useState, useRef } from 'react';
import {usePsyfyClient} from "./psyfyClient";


export const useVoiceMode = (onVoiceMessage: (message: string) => void) => {
  const [isListening, setIsListening] = useState(false); // State to track if the system is currently listening to the user's voice
  const [transcribedText, setTranscribedText] = useState("");
  const [isSpeaking, setIsSpeaking] = useState(false);  // State to track if user is currently speaking
  const isSpeakingRef = useRef(isSpeaking);
  const [volume, setVolume] = useState(0); // Volume level for visualization
  const audioContextRef = useRef<AudioContext | null>(null);  //  Reference to the audio context for volume analysis
  const analyserRef = useRef<AnalyserNode | null>(null);  // Reference to the analyser node for volume analysis
  const mediaStreamRef = useRef<MediaStream | null>(null);  // Reference to the media stream from the user's microphone
  const speakingTimeoutRef = useRef<null | ReturnType<typeof setTimeout>>(null);
  // const [isProcessing, setIsProcessing] = useState(false);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);  // Reference to the media recorder for recording audio
  const audioChunksRef = useRef<Blob[]>([]); // Reference to the audio context for volume analysis
  const [isBotSpeaking, setIsBotSpeaking] = useState(false);
  const isBotSpeakingRef = useRef(isBotSpeaking);
  const speechStartTimeRef = useRef<number | null>(null);
  const MIN_SPEECH_DURATION = 3000; // Minimum speech duration (in ms) before processing
  const [isVoiceModeEnabled, setIsVoiceModeEnabled] = useState(false);
  const isVoiceModeEnabledRef = useRef(isVoiceModeEnabled);
  const { getAudioFromBackend, sendAudioToBackend} = usePsyfyClient();
  const [isSilenceDetectionEnabled, setIsSilenceDetectionEnabled] = useState(false);
  const isSilenceDetectionEnabledRef = useRef(isSilenceDetectionEnabled);

  const [currentMode, setCurrentMode] = useState<'voice' | 'voiceOnly' | null>(null);
  const currentModeRef = useRef(currentMode);

const setCurrentModeAndRef = (value: 'voice' | 'voiceOnly' | null) => {
  setCurrentMode(value);
  currentModeRef.current = value;
};

  const setIsSilenceDetectionEnabledAndRef = (value: boolean) => {
    setIsSilenceDetectionEnabled(value);
    isSilenceDetectionEnabledRef.current = value;
  };
  
  const setIsSpeakingAndRef = (value: boolean) => {
    setIsSpeaking(value);
    isSpeakingRef.current = value;
  };

  const setIsVoiceModeEnabledAndRef = (value: boolean) => {
    setIsVoiceModeEnabled(value);
    isVoiceModeEnabledRef.current = value;
  };

  const setIsBotSpeakingAndRef = (value:boolean) => {
    setIsBotSpeaking(value);
    isBotSpeakingRef.current = value;
  };
  

  // const [manualStop, setManualStop] = useState(false);
  const startVoiceMode = () => {
    // setIsVoiceModeEnabled(true);
    setCurrentModeAndRef('voice');
    setIsVoiceModeEnabledAndRef(true);
    if (!isBotSpeaking) {
      startListening();
    }
  };

  const startVoiceOnlyMode = () => {
    setCurrentModeAndRef('voiceOnly');
    if (!isBotSpeaking) {
      startListeningVoiceOnly();
    }
  };

  const stopVoiceMode = () => {
    // setIsVoiceModeEnabled(false);
    setCurrentModeAndRef(null);
    setIsVoiceModeEnabledAndRef(false);
    if (isListening) {
      stopListening();
    }
  };

  const startListeningVoiceOnly = async () => {

    setIsSilenceDetectionEnabledAndRef(true);

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const mediaRecorder = new MediaRecorder(stream, { mimeType: 'audio/webm' });
      mediaRecorderRef.current = mediaRecorder;
  
      audioChunksRef.current = [];
  
      mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunksRef.current.push(event.data);
        }
      };
  
      mediaRecorder.onstop = async () => {
        const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/webm' });
        let transcription = await sendAudioToBackend(audioBlob); // Send audio to backend for transcription
        if (transcription) {

          transcription = transcription.replace(/\*/g, '');
          
          setTranscribedText(transcription); // Show transcription in input area
        }
  
        // Stop volume meter and update isListening state
        stopVolumeMeter();
        setIsListening(false);
  
        // Stop the media stream
        if (mediaStreamRef.current) {
          mediaStreamRef.current.getTracks().forEach((track) => track.stop());
          mediaStreamRef.current = null;
        }
      };
  
      mediaRecorder.start();
      setIsListening(true); // Set isListening to true when recording starts
      startVolumeMeter(stream); // Start the volume meter for visualization
  
      // Store the media stream for later cleanup
      mediaStreamRef.current = stream;
  
    } catch (error) {
      console.error("Error accessing microphone:", error);
    }
  };
  
  


  // Function to start listening to the user's voice
  const startListening = async () => {
    // setManualStop(false); // Reset manualStop when starting to listen

    console.log('start listening');
    // setIsSilenceDetectionEnabledAndRef(true);
    setIsSilenceDetectionEnabledAndRef(true);
    
    // Check if the browser supports getUserMedia
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      console.error('getUserMedia not supported');
      return;
    }

    try {
       // Request access to the user's microphone
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      console.log('start stream');
      mediaStreamRef.current = stream;

      const options = { mimeType: 'audio/webm' };
      const mediaRecorder = new MediaRecorder(stream, options); // Create a new MediaRecorder instance
      mediaRecorderRef.current = mediaRecorder; // Store the media recorder in a ref
 
      audioChunksRef.current = []; // Initialize the audio chunks array
     
      // Event handler for when data is available from the media recorder
      mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          console.log('ondataavailable: received data');
          audioChunksRef.current.push(event.data);
        } else {
          console.log('ondataavailable: empty data');
        }
      };
      
      // Event handler for when the media recorder starts
      mediaRecorder.onstart = () => {
        speechStartTimeRef.current = Date.now();
      };

      mediaRecorder.onstop = async () => {
        console.log('mediaRecorder.onstop called');

        // Calculate the speech duration
        const speechEndTime = Date.now();
        const speechDuration = speechEndTime - (speechStartTimeRef.current || speechEndTime);
        
          // Check if the speech duration is long enough and if there are audio chunks to process
        if (speechDuration >= MIN_SPEECH_DURATION && audioChunksRef.current.length > 0) {
          // setIsProcessing(true);
          const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/webm' });
          console.log('Audio Blob:', audioBlob);
          audioChunksRef.current = [];

          let transcription = await sendAudioToBackend(audioBlob);
          console.log('Transcription result:', transcription);

          // Only send the message if the transcription contains more than one word
          if (transcription && transcription.trim().length > 1) {
            onVoiceMessage(transcription);
            setTranscribedText(transcription);
          } else {
            console.log('Transcription too short or silent, not sending to onVoiceMessage');

             // Add a bot message indicating the issue
            onVoiceMessage("We didn't get your speech. Please say it again.");
          }

          // setIsProcessing(false);
        } else {
          console.log('Speech duration too short or no audio chunks to process');
           // Add a bot message indicating the issue
          onVoiceMessage("We didn't get your speech. Please say it again.");
          stopVoiceMode(); 
        }

        if (mediaStreamRef.current) {
          mediaStreamRef.current.getTracks().forEach((track) => track.stop());
          mediaStreamRef.current = null;
        }
        if (mediaRecorderRef.current) {
          mediaRecorderRef.current = null;
        }
        stopVolumeMeter();
        
        // Set isListening to false after processing
        setIsListening(false);
      };
     
      // start record after successfully obtaining the user's microphone stream
      mediaRecorder.start();
      setIsListening(true); //bot is listening to the speaker

      // Ensure mediaRecorderRef.current is set before starting volume meter
      startVolumeMeter(stream);
    } catch (error) {
      console.error('Error accessing microphone', error);
    }
  };
 
    // Function to stop listening to the user's voice
  const stopListening = () => {
    if (!mediaRecorderRef.current) {
      // If mediaRecorderRef.current is null, we are already not listening
      return;
    }

    console.log('stopListening called');
    // setManualStop(true);
    mediaRecorderRef.current.stop();
    setIsListening(false); // Set isListening to false when stopping

     if (currentModeRef.current === 'voiceOnly') {
        setCurrentModeAndRef(null);
        setIsVoiceModeEnabledAndRef(false);
       }
  };


 
   // Function to start the volume meter for visualizing the audio input
   const startVolumeMeter = (stream: MediaStream) => {
    // Check if the AudioContext is supported by the browser
    const AudioContext = window.AudioContext || (window as any).webkitAudioContext;
    audioContextRef.current = new AudioContext();
  
    if (audioContextRef.current) {
      const source = audioContextRef.current.createMediaStreamSource(stream); // Connect the audio stream to the audio context
      const analyser = audioContextRef.current.createAnalyser(); // Create an analyser for volume level analysis
      analyser.fftSize = 2048; // Set the Fast Fourier Transform size to analyze the frequency data
      source.connect(analyser); // Connect the audio source to the analyser
      analyserRef.current = analyser;
  
      const dataArray = new Uint8Array(analyser.frequencyBinCount); // Array to hold frequency data for analysis
  
      const updateVolume = () => {
        analyser.getByteFrequencyData(dataArray); // Fill dataArray with frequency data from the analyser
        let values = 0;
  
        // Calculate the average volume from the frequency data
        for (let i = 0; i < dataArray.length; i++) {
          values += dataArray[i];
        }
        const average = values / dataArray.length; // Average volume level
        setVolume(average); // Update the state with the current volume level for visual feedback

        if (isBotSpeakingRef.current) {
          // Bot is speaking, do not process silence detection
          requestAnimationFrame(updateVolume);
          return;
        }

        if (isSilenceDetectionEnabledRef.current) {
          if (average > 10) {
            console.log('detect silence')
            if (!isSpeakingRef.current) {
              setIsSpeakingAndRef(true);
            }
            if (speakingTimeoutRef.current) {
              clearTimeout(speakingTimeoutRef.current);
              speakingTimeoutRef.current = null;
            }
          } else {
            if (!speakingTimeoutRef.current) {
              speakingTimeoutRef.current = setTimeout(() => {
                if (isSpeakingRef.current) {
                  setIsSpeakingAndRef(false);
                }
                stopListening();
                speakingTimeoutRef.current = null;
              }, 3000);
  
            }
          }
        } else {
          // When silence detection is disabled (for voice-only mode)
          if (average > 10) {
            // User is speaking, volume is high enough
            if (!isSpeaking) {
              setIsSpeakingAndRef(true);
              // setIsSpeaking(true); // Update speaking state to true
            }
          } else {
            // Silence detected, volume is low, but do not stop listening
            if (isSpeaking) {
              setIsSpeakingAndRef(false);
              // setIsSpeaking(false); // User has stopped speaking
            }
          }
          // In voice-only mode, we don't stop listening based on silence
        }
  
        // Continuously update the volume levels on the next animation frame
        requestAnimationFrame(updateVolume);
      };
  
      // Start the volume meter
      updateVolume();
    }
  };


  const stopVolumeMeter = () => {
    if (speakingTimeoutRef.current) {
      clearTimeout(speakingTimeoutRef.current);
      speakingTimeoutRef.current = null;
    }
    if (analyserRef.current) {
      analyserRef.current.disconnect();
      analyserRef.current = null;
    }
    if (audioContextRef.current) {
      audioContextRef.current.close();
      audioContextRef.current = null;
    }
    setVolume(0);
    setIsSpeaking(false);
  };

  // Helper function to convert base64 to Blob
    const base64ToBlob = (base64: string, mimeType: string): Blob => {
      const byteCharacters = atob(base64);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
      }

      return new Blob(byteArrays, { type: mimeType });
    };

    // Function to make the bot speak the given texts
  const speak = (texts: string[]): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      if (!('speechSynthesis' in window)) {
        alert('Your browser does not support speech synthesis.');
        reject('Speech synthesis not supported');
        return;
      }

       // Stop listening while the bot is speaking
      // if (isListening) {
      //   stopListening();
      // }
      if (isBotSpeaking) {
        stopListening(); // Stop listening if currently listening to avoid recording its own speech
      }
      // setIsBotSpeaking(true);
      setIsBotSpeakingAndRef(true);
      console.log('set is bot speaking')

      const selectVoiceAndSpeak = (voices: SpeechSynthesisVoice[]) => {
        const selectedLanguage = localStorage.getItem('language') || 'english';
        const langCode = selectedLanguage.toLowerCase() === 'chinese' ? 'zh-CN' : 'en-US';



        let currentUtteranceIndex = 0;
        const totalUtterances = texts.length;

        const speakNext = async () => {
          if (currentUtteranceIndex < totalUtterances) {
            const text = texts[currentUtteranceIndex];
  
            // Call the backend to get audio content
            const base64Audio = await getAudioFromBackend(text, langCode);
  
            // Convert base64 to Blob
            const audioBlob = base64ToBlob(base64Audio, 'audio/mp3');
  
            // Create a URL for the Blob
            const audioUrl = URL.createObjectURL(audioBlob);
  
            // Create an Audio object
            const audio = new Audio(audioUrl);
  
            // Handle when the audio ends
            audio.onended = () => {
              // Revoke the object URL to free up memory
              URL.revokeObjectURL(audioUrl);
              handleNextUtterance();
            };
  
            // Handle errors in audio playback
            audio.onerror = (error) => {
              console.error('Audio playback error: ', error);
              // Revoke the object URL to free up memory
              URL.revokeObjectURL(audioUrl);
              handleNextUtterance();
            };
  
            // Play the audio
            audio.play();
          } else {
            // All texts have been spoken
            setIsBotSpeaking(false);
            resolve();
          }
        };
  

         // Function to handle the next utterance
         const handleNextUtterance = () => {
          currentUtteranceIndex++;
          if (currentUtteranceIndex < totalUtterances) {
            speakNext();
          } else {
            // setIsBotSpeaking(false);
            setIsBotSpeakingAndRef(false);
        
            if (isVoiceModeEnabledRef.current) {
              if (isSilenceDetectionEnabledRef.current) {
                // Headphone mode
                startListening();
              } else {
                // Voice-only mode
                startListeningVoiceOnly();
              }
            }
            resolve();
          }
        };
    

        speakNext();
      };

      const handleVoicesChanged = () => {
        const voices = window.speechSynthesis.getVoices();
        selectVoiceAndSpeak(voices);
        window.speechSynthesis.removeEventListener('voiceschanged', handleVoicesChanged);
      };

      // Ensure voices are loaded before speaking
      if (window.speechSynthesis.getVoices().length === 0) {
        window.speechSynthesis.addEventListener('voiceschanged', handleVoicesChanged);
      } else {
        const voices = window.speechSynthesis.getVoices();
        selectVoiceAndSpeak(voices);
      }
    });
  };

  return {
    isListening,
    startListening,
    stopListening,
    speak,
    isSpeaking,
    volume,
    startVoiceMode,
    stopVoiceMode,
    startVoiceOnlyMode,
    isVoiceModeEnabled,
    transcribedText,
    setTranscribedText,
    startListeningVoiceOnly,
    isVoiceModeEnabledRef,
    currentModeRef,
    
  };
};
